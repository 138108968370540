'use strict';

export default vars;

function vars(){

  var screenWidth = $(window).width();
  //console.log('vars()',screenWidth)
  return screenWidth;
  
}